<template>
  <base-page
    :title="$t('roleManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.roleFind"
      :columns="columns"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 角色类型 -->
      <template v-slot:roleType="{ row }">
        {{ roleTypeObj[row.roleType] }}
      </template>
    </table-list>
    <base-dialog
      v-if="showDialogMultipleTreeBase"
      :showConfirm="false"
      @cancel="showDialogMultipleTreeBase = false"
    >
    <el-row>
      <el-col :span="4" :offset="10" style="margin-bottom:10px;cursor: pointer;">
        <div @click="getCurrentRolePermission('CORE')">{{$t('businessEnum.CORE')}}</div>
      </el-col>
    </el-row>
    <el-row v-for="(item,index) in productConfigEntities" :key="index">
      <el-col :span="12" :offset="10" style="margin-bottom:10px;cursor: pointer;">
        <div @click="getCurrentRolePermission(item.type)" >
          <template v-if="item.type === 'TMVA'">{{$t('businessEnum.TMVA')}}</template>
          <template v-if="item.type === 'XR_WORK'">{{$t('businessEnum.XR_WORK')}}</template>
          <template v-if="item.type === 'TMVA_IDC'">{{$t('businessEnum.TMVA_IDC')}}</template>
          <template v-if="item.type === 'AI_STATION'">{{$t('businessEnum.AI_STATION')}}</template>
        </div>
      </el-col>
    </el-row>
    <dialog-tree
      :title="title"
      v-if="showDialogMultipleTree"
      height="400px"
      :isDefine="true"
      :defaultExpandedKeys="[1]"
      :defineConfirm="true"
      :defaultContent="defaultContent"
      @cancel="showDialogMultipleTree = false"
      @confirm="roleSetPermission"
    >
      <template v-slot:default="{ data }">
        <div
          class="treeList"
          style="display: flex; justify-content: space-between; width: 100%"
        >
          <div>
            <el-checkbox
              class="canUseRadio"
              v-model="data.selected"
              @change="changeSelected($event, data, 'selected')"
            />
            {{ $t(`functionEnum.${data.permissionEnum}`) }}
          </div>
          <div v-if="data.selected">
            <el-checkbox
              :style="{
                position: 'relative',
                left: styleLeft[data.permissionType],
              }"
              class="canUseRadio"
              v-model="data.canUse"
              @change="changeSelected($event, data, 'canUse')"
              >{{ $t('base.canUse') }}</el-checkbox
            >
          </div>
        </div>
      </template>
    </dialog-tree>
    </base-dialog>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'
import {roleSetPermission} from "@/api";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('base.nameOrNotes'),
          },
        },
        {
          tag: 'el-select',
          label: this.$t('roleManage.type'),
          prop: 'roleType',
          span: 6,
          tagProps: {
            options: this.$dict.roleType(),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      roleTypeObj: this.$util.listToObj(this.$dict.roleType()),
      tableRow: null,
      tableRowNew:null,
      tableRowNew1:null,
      tableRowNew2:null,
      tableRowNew3:null,
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:角色名称
        {
          label: this.$t('roleManage.name'),
          prop: 'name',
        },
        // lang:角色类型
        {
          label: this.$t('roleManage.type'),
          prop: 'roleType',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 授权
      showDialogMultipleTreeBase:false,
      showDialogMultipleTree: false,
      title:'',
      defaultParams: {
        subjectType: 'PC_MANAGE',
      },
      noCanUseIds: [],
      // 表单页
      showDetail: false,
      // 弹出树
      defaultContent: [],
      styleLeft: {
        ROOT: '-60px',
        ONE_LIST: '-40px',
        TWO_LIST: '-20px',
        BUTTON: '0px',
      },
    }
  },
  computed: {
    // 操作员信息
    operator() {
       const { operator } = this.$store.state.operator
       return operator || {}
    },
    productConfigEntities() {
      const { productConfigEntities} = this.$store.getters
     // if(JSON.stringify(productConfigEntities) !== '{}'){
        return this.$util.sortKey(productConfigEntities,'id')
     // }
    }
  },
  methods: {
    // 处理子节点
    handleChildren(data, isSelected, key) {
      if (data.children && data.children.length) {
        data.children.forEach((item1) => {
          item1[key] = isSelected
          if (item1.children && item1.children.length) {
            item1.children.forEach((item2) => {
              item2[key] = isSelected
              if (item2.children && item2.children.length) {
                item2.children.forEach((item3) => {
                  item3[key] = isSelected
                })
              }
            })
          }
        })
      }
    },
    // 处理父节点
    handleParent(data, isSelected, key) {
      // 当子节点选中时
      if (isSelected) {
        this.defaultContent[0][key] = true
        // 当选中的是二级功能时
        if (data.permissionType === 'TWO_LIST') {
          this.defaultContent[0].children.forEach((item) => {
            if (item.children && item.children.length) {
              item.children.forEach((list) => {
                if (list.id === data.id) {
                  item[key] = true
                }
              })
            }
          })
          return
        }
        // 当选中的是按钮功能时
        if (data.permissionType === 'BUTTON') {
          this.defaultContent[0].children.forEach((item) => {
            if (item.children && item.children.length) {
              item.children.forEach((list) => {
                if (list.permissionType === 'BUTTON') {
                  item.children.forEach((list) => {
                    if (list.id === data.id) {
                      item[key] = true
                    }
                  })
                } else {
                  item.children.forEach((list) => {
                    if (list.children && list.children.length) {
                      list.children.forEach((ls) => {
                        if (ls.id === data.id) {
                          item[key] = true
                          list[key] = true
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
        return
      }
      // 取消选中一级功能
      if (data.permissionType === 'ONE_LIST') {
        let hasSelected = false
        this.defaultContent[0].children.forEach((item) => {
          if (item[key]) hasSelected = true
        })
        if (!hasSelected) {
          this.defaultContent[0][key] = false
        }
        return
      }
      // 取消选中二级功能
      if (data.permissionType === 'TWO_LIST') {
        // 处理一级父级
        this.defaultContent[0].children.forEach((item) => {
          if (item.children && item.children.length) {
            item.children.forEach((list) => {
              if (data.id === list.id) {
                let hasSelected = false
                item.children.forEach((ls) => {
                  if (ls[key]) {
                    hasSelected = true
                  }
                })
                if (!hasSelected) item[key] = false
              }
            })
          }
        })
        // 处理全部按钮
        let hasSelected = false
        this.defaultContent[0].children.forEach((item) => {
          if (item[key]) hasSelected = true
        })
        if (!hasSelected) {
          this.defaultContent[0][key] = false
        }
        return
      }
      // 取消选中按钮功能
      if (data.permissionType === 'BUTTON') {
        this.defaultContent[0].children.forEach((item) => {
          if (item.children && item.children.length) {
            item.children.forEach((list) => {
              if (list.permissionType === 'BUTTON') {
                if (list.id === data.id) {
                  let hasSelected = false
                  item.children.forEach((a) => {
                    if (a[key]) hasSelected = true
                  })
                  if (!hasSelected) item[key] = false
                }
              } else {
                if (list.children && list.children.length) {
                  list.children.forEach((ls) => {
                    if (ls.id === data.id) {
                      // 处理二级
                      let hasSelected = false
                      list.children.forEach((a) => {
                        if (a[key]) hasSelected = true
                      })
                      if (!hasSelected) list[key] = false
                      // 处理一级
                      hasSelected = false
                      item.children.forEach((a) => {
                        if (a[key]) hasSelected = true
                      })
                      if (!hasSelected) item[key] = false
                    }
                  })
                }
              }
            })
          }
        })
        // 处理全部按钮
        let hasSelected = false
        this.defaultContent[0].children.forEach((item) => {
          if (item[key]) hasSelected = true
        })
        if (!hasSelected) {
          this.defaultContent[0][key] = false
        }
      }
    },
    // 选择权限按钮
    changeSelected(e, data, key) {
      console.log(e, data, key)
      // 说明选中了次节点
      if (e) {
        // 勾选当前节点以及子节点
        this.handleChildren(data, true, key)
        // 勾选父节点
        this.handleParent(data, true, key)
        return
      }
      // 取消勾选当前节点以及子节点
      this.handleChildren(data, false, key)
      // 取消勾选父节点
      this.handleParent(data, false, key)
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'grantPermissions': {
          this.showDialogBase()
          console.log(this.tableRow)
          console.log('this.productConfigEntities',this.productConfigEntities)
          this.getCorePermissions(this.tableRow.uid)
          if(this.productConfigEntities.filter(item => item.type === 'TMVA').length > 0) {
            this.getTmvaPermissions(this.tableRow.uid)
          }
          if(this.productConfigEntities.filter(item => item.type === 'XR_WORK').length > 0) {
            this.getWorkflowPermissions(this.tableRow.uid)
          }
          if(this.productConfigEntities.filter(item => item.type === 'TMVA_IDC').length > 0) {
            this.getTmvaIdcPermissions(this.tableRow.uid)
          }
          if(this.productConfigEntities.filter(item => item.type === 'AI_STATION').length > 0) {
            this.getTmvaAiStationPermissions(this.tableRow.uid)
          }
          break
        }
        default: // do something
      }
    },
    // 弹出总模块
    showDialogBase() {
      this.showDialogMultipleTreeBase = true
      console.log(this.productConfigEntities)
      // this.$ajax({
      //   url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getProductEnum,
      // }).then((data) => {
      //   console.log(data)
      //   this.typeListData = data
      //   this.showDialogMultipleTreeBase = true
      // })
    },
    // 远程指导回显TMVA
    getTmvaPermissions(row) {
      this.$ajax({
        url: '/tmva' + this.$api.findInfo,
        data:{
          uid:row
        }
      }).then((data) => {
        this.tableRowNew = data
      })
    },
    // 远程指导回显XRWORK
    getWorkflowPermissions(row) {
      this.$ajax({
        url: '/workFlow' + this.$api.findInfo,
        data:{
          uid:row
        }
      }).then((data) => {
        this.tableRowNew1 = data
      })
    },
    getTmvaIdcPermissions(row) {
      this.$ajax({
        url: '/idc' + this.$api.findInfoCore,
        data:{
          uid: row
        }
      }).then((data) => {
        this.tableRowNew2 = data
      })
    },
    getTmvaAiStationPermissions(row) {
      this.$ajax({
        url: '/tmvaAiStation' + this.$api.findInfoCore,
        data:{
          uid: row
        }
      }).then((data) => {
        this.tableRowNew3 = data
      })
    },
    // 账号管理回显core
    getCorePermissions(row) {
      this.$ajax({
        url: '/core' + this.$api.findInfoCore,
        data:{
          uid:row
        }
      }).then((data) => {
        this.tableRow = data
      })
    },
    // 账号管理可分配功能
    getCurrentRolePermission(item) {
      let baseUrl = ""
      let tableRowData = this.tableRow
      // console.log(item)
      if(item === 'CORE') {
        this.title = this.$t('roleManage.accountAuthorization')
        baseUrl = '/core' + this.$api.getCurrentRolePermission
      }
      if(item === 'TMVA') {
        this.title = this.$t('roleManage.guidanceAuthorization')
        baseUrl = '/tmva' + this.$api.getCurrentRolePermission
        tableRowData = this.tableRowNew
      }

      if(item === 'XR_WORK') {
        this.title = this.$t('roleManage.workflowAuthorization')
        baseUrl = '/workFlow' + this.$api.getCurrentRolePermission
        tableRowData = this.tableRowNew1
      }

      if(item === 'TMVA_IDC') {
        this.title = this.$t('roleManage.tmvaIdcAuthorization')
        baseUrl = '/idc' + this.$api.getCurrentRolePermission
        tableRowData = this.tableRowNew2
      }

      if(item === 'AI_STATION') {
        this.title = this.$t('roleManage.tmvaAiStationAuthorization')
        baseUrl = '/tmvaAiStation' + this.$api.getCurrentRolePermission
        tableRowData = this.tableRowNew3
      }

      if(tableRowData === null) {
        tableRowData = {
          roleType: null,
          permissions: null,
        }
      }

      setTimeout(() => {
        const { roleType, permissions } = tableRowData

        // console.log('permissions', permissions)
        const params = {
          subjectType: 'PC_MANAGE',
        }
        if (roleType === 'SUB') params.canUse = 'YES'
        this.$ajax({
          url: baseUrl,
          data: params,
        }).then((data) => {
          data.typeStr = item
          if (permissions && permissions.length) {
            // 全部功能
            data.selected = false
            data.canUse = false
            permissions.forEach((a) => {
              if (data.id === a.permission.id) {
                data.selected = true
                if (a.canUse === 'YES') data.canUse = true
              }
            })
            // 一级功能
            data.children.forEach((item) => {
              item.selected = false
              item.canUse = false
              permissions.forEach((a) => {
                if (item.id === a.permission.id) {
                  item.selected = true
                  if (a.canUse === 'YES') item.canUse = true
                }
              })
              // 二级功能
              if (item.children && item.children.length) {
                item.children.forEach((list) => {
                  list.selected = false
                  list.canUse = false
                  permissions.forEach((a) => {
                    if (list.id === a.permission.id) {
                      list.selected = true
                      if (a.canUse === 'YES') list.canUse = true
                    }
                  })
                  // 按钮功能
                  if (list.children && list.children.length) {
                    list.children.forEach((ls) => {
                      ls.selected = false
                      ls.canUse = false
                      permissions.forEach((a) => {
                        if (ls.id === a.permission.id) {
                          ls.selected = true
                          if (a.canUse === 'YES') ls.canUse = true
                        }
                      })
                    })
                  }
                })
              }
            })
          } else {
            // 全部功能
            data.selected = false
            data.canUse = false
            // 一级功能
            data.children.forEach((item) => {
              item.selected = false
              item.canUse = false
              // 二级功能
              if (item.children && item.children.length) {
                item.children.forEach((list) => {
                  list.selected = false
                  list.canUse = false
                  // 按钮功能
                  if (list.children && list.children.length) {
                    list.children.forEach((ls) => {
                      ls.selected = false
                      ls.canUse = false
                    })
                  }
                })
              }
            })
          }
          this.defaultContent = [data]
          this.showDialogMultipleTree = true
          // console.log('defaultContent', this.defaultContent)
        })
      }, 500)
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.roleDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 授权
    roleSetPermission() {
      const rolePermissionSet = []
      // 判断接口
      let baseUrl = ""
      console.log(this.defaultContent[0].typeStr)
      if(this.defaultContent[0].typeStr === 'CORE') {
        baseUrl = '/core'  + this.$api.roleSetPermission
      } else if(this.defaultContent[0].typeStr === 'TMVA'){
        baseUrl = '/tmva'  + this.$api.roleSetPermissionV1
      } else if(this.defaultContent[0].typeStr === 'XR_WORK') {
        baseUrl = '/workFlow' + this.$api.roleSetPermissionV1
      }else if(this.defaultContent[0].typeStr === 'TMVA_IDC') {
        baseUrl = '/idc' + this.$api.roleSetPermission
      }else if(this.defaultContent[0].typeStr === 'AI_STATION') {
        baseUrl = '/tmvaAiStation' + this.$api.roleSetPermission
      }
      // 全部功能
      this.defaultContent.forEach((item) => {
        if (item.selected) {
          rolePermissionSet.push({
            permission: item,
            canUse: item.canUse ? 'YES' : 'NO',
          })
        }
        delete item.selected
        delete item.canUse
        // 一级功能
        if (item.children && item.children.length) {
          item.children.forEach((list) => {
            if (list.selected) {
              rolePermissionSet.push({
                permission: list,
                canUse: list.canUse ? 'YES' : 'NO',
              })
            }
            delete list.selected
            delete list.canUse
            // 二级功能
            if (list.children && list.children.length) {
              list.children.forEach((ls) => {
                if (ls.selected) {
                  rolePermissionSet.push({
                    permission: ls,
                    canUse: ls.canUse ? 'YES' : 'NO',
                  })
                }
                delete ls.selected
                delete ls.canUse
                // 按钮功能
                if (ls.children && ls.children.length) {
                  ls.children.forEach((a) => {
                    if (a.selected) {
                      rolePermissionSet.push({
                        permission: a,
                        canUse: a.canUse ? 'YES' : 'NO',
                      })
                    }
                    delete a.selected
                    delete a.canUse
                  })
                }
              })
            }
          })
        }
      })
      if (!rolePermissionSet.length) {
        this.$element.showMsg(this.$t('base.selectTreeNode'), 'warning')
        return
      }
      console.log(rolePermissionSet);
      this.$ajax({
        url: baseUrl,
        data: {
          uid: this.tableRow.uid,
          rolePermissionSet,
        },
      }).then(() => {
        this.showDialogMultipleTree = false
        this.getTmvaPermissions(this.tableRow.uid) // TMVA回显更新
        this.getCorePermissions(this.tableRow.uid) // CORE回显更新
        this.getWorkflowPermissions(this.tableRow.uid) //WORKFLOW 回显更新
        this.getTmvaAiStationPermissions(this.tableRow.uid) //AI_STATION 回显更新
        // lang:分配成功
        this.$element.showMsg(this.$t('base.distributionSuccess'), 'success')
        // this.search()
      })
    },
  },
}
</script>
<style scoped>
.btnClass{
  cursor: pointer;
}
</style>
